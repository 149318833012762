.my-card
    margin-top: 200px
    &__title
        text-align: center
        margin-bottom: 20px
    &__form
        display: flex
        flex-direction: column
    &__input
        margin-bottom: 15px!important
    &__btn
        width: 100px